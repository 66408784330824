/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/
.mobileOnly {display:none;}
.desktopOnly {display:inline-block;}


h1, .h1 { font-size: 2.5em;}
h2, .h2, #homeMission {font-size: 1.8em;}
 .bigger, h3, .h3, h4, .h4 {font-size: 1.2em;}
.submenu a {font-size: 0.9em;}
ul.responsive3 .slick-list .slick-track .slick-slide .slideshow_caption .slideDescription {font-size:1em;}

#homeMission {
    font-weight: 700;
}

/*************************
Colours
*************************/

.white { color: $white; }


/*********************
LAYOUT & GRID STYLES
*********************/
.row, body.Home #content #leftcol > div > div, body.fullWidth #leftcol > div > div{
	padding: 2em 0;
}

body.Home #content #leftcol > div > #hero {
    padding: 110px 0 2em;
}

/* Reduce padding on some rows*/
#breadcrumbs .row {padding: 1em 0;}

/*Increase padding content some rows */

/* Restrict width on some rows and content areas that behave like rows*/

		
/*********************
SPRITE
*********************/
		

    #logo {
        width: 167px; height: 80px;
	    background-size: 167px 80px;
	}

/*********************
HEADER STYLES
*********************/

body.Home .header {
	/*height: 112.09px;*/
}

.header {

	    .row {
		    position: relative;
            min-height: 90px;
	    }

	    #logo, #accountMenu {
            transform: none;
	    }

        #logo {
            left: 0%;
            top:0.5em;
        }

	    #accountMenu {
			top: 10%;
            right:0;
	    }

        #mainMenu {
            display: inline-block;
            position: absolute;
            right: 0%;
            bottom:10%;
        }
     		
}



/**************************
HERO - Home
***************************/

body.Home #hero {
    overflow:hidden;
    .row {
    width:80%;
    padding:2em;
    }
}
    



/*********************
NAVIGATION STYLES
*********************/

/*#touchMenu*/
.menu-btn {display:none;}

body:not(.admindex) .topMenu .nav > li:last-child { display: none; }

.topMenu {
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    float: right;
    clear: none;


    a.toggleMenu {
        display: none;
    }

    .nav {
        position: relative;
        width: auto;
        border: 0;
        display: inline;


        > li {
            display: inline-block;
            padding: 0 0.4em;

            > a {
                outline: none;

                &:hover, &:focus {
                    background: transparent;
                }

                &.parent:after {
                    content: "\f107";
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
                    display: block;
                    float: right;
                    font-size: large;
                    padding-left: 0.3em;
                    line-height: 1.5;
                }
            }
            /*Prominent contact link*/
            &:first-child {
                display: none;
            }

            &:last-child {
                //display: none;
            }
            /*&:last-child {
                > a {
                    background: $color-secondary;
                    @include border-radius($small-border-radius);
                    padding: 0.5em 1em;
                    @include transition(all 0.5s ease-in-out);

                    &:hover, &:focus, &:active, &.activerootmenulink {
                        background: darken($color-secondary, 15%);
                        @include transition(all 0.5s ease-in-out);
                        color: $white;
                    }
                }
            }*/
            /*turn some of the main nav items OFF for public*/
            /*&:first-child > a{
				display:none;
			}
			
			&:nth-child(6) {
				display:none;
			}
			&:nth-child(7) {
				display:none;
			}
			*/
        }

        li {
            position: relative;

            a {
                padding: 0.5em 0em;
                background: none;
                border-bottom: none;


                &.parent:before {
                    display: none;
                }
            }
            /* highlight current page */
            a.activerootmenulink {
                color: $color-tertiary;
            }

            &:first-child {
            }

            &:last-child {
            }
            /*
			plan your menus and drop-downs wisely.
			*/
            ul,
            ul.sub-menu,
            ul.children {
                position: absolute;
                z-index: 9999;
                left: -9999px;
                border-top: 2px solid $color-secondary;

                li {

                    a {
                        padding: 0.5em 0.5em;
                        display: block;
                        width: 250px;
                        border-top: none;
                        border-radius: 0;
                        margin-right: 0;


                        &:hover,
                        &:focus {
                            border-top: none;
                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {

                        a {
                        }
                    }
                    /*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
                    ul {
                        border-top: none;
                    }
                }
            }
            /* showing sub-menus */
            &:hover ul {
            }
        }
        /* end .menu ul li */
    }
    /* end .nav */
} /* end .topMenu */

.nav > li.hover > ul { left: 0;}
.nav li li ul { left: -9999px; z-index: 99; position:absolute; }
.nav li li.hover ul { left: 100%; top: 0; z-index: 99; position:absolute; }

/* active state on home - not sure where else to put this for now*/


/*********************
BLOCK LAYOUT
**********************/	


.columnlayout {	
	> div, li {	width:33%;	}
    article.newsBlock {width: 25%; }
}
#gallery li {width: 25%;}



    /*Bottom Blocks*/

    #bottomBlocks .block {
        margin-bottom: 2em;
        margin: 0 auto;
    }

    /*layout needed for list items */
    #bottomBlocks #block_26 {

        ul {
           
            li {
                display:inline-block;
                width: 48%;
                margin-bottom: 0.5em;

            }
        }

    }

  

/*Full width slider */
body.Home #hero {
	overflow:hidden;
	.slick-slider { }
}
body.Home #content #leftcol > div > #hero {
    padding: 90px 0 0;
}

body:not(.Home) #hero {
    margin: 90px 0 0;
}

ul.responsive3 {
    max-height: none;

    .slick-list {

        .slick-track {

            .slick-slide {
                padding-bottom: 57px;
                //max-height: 685px;
                //height: 89vh;
                &:before {
                    background-color: rgba(0, 0, 0, 0.20);
                }

                img {
                    //height: 685px;
                    //height: 560px;
                }

                .slideshow_caption {
                    //width: 50%;
                    width: calc(87% + 10px);
                    text-align: right;

                    &:after {
                        background: inherit;
                        top: 0;
                        content: '';
                        display: block;
                        height: 100%;
                        width: 80px;
                        position: absolute;
                        right: -20px;
                        transform: skewX(-15deg);
                        transform-origin: 100%;
                        z-index: -1;
                    }

                    .slideDescription, a.button {
                        display: inline-block;
                    }

                    .slideDescription {
                        padding: 1em;
                        padding-right: 0;
                    }

                    a.button {
                        height: 100%;
                        background: $color-primary;
                        border-radius: 0;
                        text-decoration: none;
                        position: absolute;
                        z-index: -2;
                        white-space: nowrap;
                        padding: 0 0 0 35px;
                        line-height: 3;
                        margin-top: 0;
                        top: 0;
                        border: none;

                        &:hover {
                            background: lighten($black, 5%);
                        }

                        &:after {
                            background: inherit;
                            top: 0;
                            content: '';
                            display: block;
                            height: 100%;
                            width: 80px;
                            position: absolute;
                            right: -20px;
                            //transform: skewX(-15deg);
                            //transform-origin: 100%;
                            z-index: -1;
                        }
                    }
                }
            }

            .slick-active img {
                margin-left: 0px;
            }
        }
    }
}

.slick-prev, .slick-next {
    top: 40%;
}

.slick-slider .slick-active img {
    margin-left: 0;
}


/*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        height: 50px;
        width: 50px;
    }

/*********************
Sticky CTA
*********************/

#stickyCTA {
	 
}