/******************************************************************
Site Name: 
Author: 

Stylesheet: Desktop Stylsheet

This is the desktop size. It's larger than an iPad so it will only
be seen on the Desktop. 

******************************************************************/
//.clearHeader .nav > li > a {color:$white;}
.bigger {font-size:170%;}
.header {

    /*.clearHeader {
    background-color: rgba(0, 0, 0, 0.1);
    border-bottom:1px solid $white;
        position: absolute;


        > .row {
            min-height: 120px;
        }

        #logo {
            width: 230px;
            height: 110px;
            background-size: 230px 110px;
            @include transition(all 0.3s ease-in-out);
        }
    }*/

    .darkHeader,
    .clearHeader {

        > .row {
            min-height: 130px;
        }

        #logo {
            width: 209px;
            height: 110px;
            background-size: 209px 110px;
            @include transition(all 0.3s ease-in-out);
        }
    }
}


#content {height:auto;}
body.Home #content #leftcol > div > #hero {
    padding: 130px 0 0;
}
body:not(.Home) #hero {
    margin: 130px 0 0;
}